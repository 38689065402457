<template>
  <b-container class="mt-4 mb-5" fluid>
    <b-card no-body class="bs-br py-3 px-3">
      <h5 class="fw-bold m-0">Filters</h5>
      <hr class="mt-2">
      <b-row class="mb-3">
        <b-col cols="12" sm="12" md="6" xl="3" class="mb-2 mb-xl-0">
          <b-form-group label="Fields" label-for="fields" label-class="fw-bold">
            <b-form-select :options="filters.fields" id="fields" class="form-select"
              v-model="filters.field" @change="addFilter">
              <template #first>
                <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="12" md="6" xl="3" class="mb-2 mb-xl-0">
          <b-form-group label="Start date" label-for="start-date" label-class="fw-bold">
            <b-form-datepicker locale="en-US" reset-button id="start-date" v-model="filters.start_date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" xl="3" class="mb-3 mb-xl-0">
          <b-form-group label="End date" label-for="end-date" label-class="fw-bold">
            <b-form-datepicker locale="en-US" reset-button id="end-date" v-model="filters.end_date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" xl="4" v-for="(input, index) in filter_fields" :key="index" class="dynamic-filter mb-2">
          <b-input-group :prepend="input.description">
            <b-form-input aria-label="First name" v-model="input.value"></b-form-input>
            <div class="trash ms-2" @click="removeFilter(index, input)">
              <vue-fontawesome icon="trash"></vue-fontawesome>
            </div>
          </b-input-group>

        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col cols="12" md="auto" align-self="end">
          <b-button pill class="px-4 fw-bold" @click="filter()">Filter</b-button>
        </b-col>
      </b-row>

    </b-card>
    <b-card class="justify-content-center bs-br mt-3">
      <h5 class="fw-bold">{{users_suscribed.data.length > 0 ? users_suscribed.data[0].form.name : ''}}</h5>

      <div class="text-end">
        <b-button pill class="px-4 fw-bold" id="popover-target-1">Columns</b-button>
        <b-popover target="popover-target-1" triggers="hover" placement="left">
          <template #title>Columns table</template>
          <b-form-checkbox-group
            v-model="selected"
            :options="columnsFilter"
            class="check-filters">
          </b-form-checkbox-group>
          <b-button pill class="px-4 fw-bold" @click="setColumnTable()">Show columns</b-button>
        </b-popover>
      </div>


      <hr class="my-2" v-if="users_suscribed.data.length > 0">
      <b-table hover :items="rows" :fields="fields" :busy="load"
        responsive tbody-tr-class="transition-tr" table-class="table-forms"
        v-if="users_suscribed.data.length > 0">
        <template #table-busy>
          <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
          </div>
        </template>

        <!-- <template #cell(input_values)="row">
          <div v-for="(input, index) in row.item.input_values" :key="index">
            <template #cell(input_values)="row">
              {{  filters.fields.find(field => field.value == input.field_id)  }}
            </template>
          </div>
        </template> -->


        <!-- <template #cell(created_at)="row">
          {{ new Date(row.item.created_at).toLocaleDateString('en-US')}}
        </template> -->
        <!-- <template #cell(actions)="row" v-if="$route.name == 'forms.suscribed'">
          <b-button variant="default" v-if="checkPermission('See Stats Form - Marketing')" class="p-0"
            @click="$router.push({name: 'forms.submitted_form', params: {form: $route.params.form, email: row.item.email}})">
            <i class='bx bx-show-alt' style="font-size: 25px; color: #8E8E8E;"></i>
          </b-button>
        </template> -->
      </b-table>
      <div v-else>
        <h5 class="mb-0 fw-bold">No subscribed users found</h5>
      </div>
      <b-pagination
          v-model="users_suscribed.pagination.current_page"
          pills align="right"
          :total-rows="users_suscribed.pagination.total"
          :per-page="users_suscribed.pagination.per_page"
          @change="getUsersSuscribed"
          v-if="users_suscribed.data.length > 0"
      ></b-pagination>
    </b-card>

  </b-container>
</template>
<script>
export default {
  name: 'users_suscribed',
  data() {
    return {
      form: [],
      users_suscribed: {
        data: [],
      },
      rows: [],
      fields: [
        { key: 'email', label: 'Email', sortable: true },
        // { key: 'created_at', label: 'Created at', sortable: true},
        this.$route.name == 'forms.suscribed' ? { key: 'actions', label: '' } : ''
      ],
      filters: {
        fields: [],
        field: null,
        start_date: null,
        end_date: null,
      },
      columnsFilter: [],
      filter_fields: [],
      selected: [],
      load: false
    };
  },
  mounted() {
    this.getForm();
  },
  methods: {
    getUsersSuscribed(page){
      this.load = true;
      this.$http.get(`marketing/dynamic_forms/submitted_forms/${this.$route.params.form}`, {
        params: {
          start_date: this.filters.start_date,
          end_date: this.filters.end_date,
          dynamic_filters: this.filter_fields,
          page: page
        },
        headers: {
          Authorization: `Bearer ${this.$session.get('jwt')}`
        }
      }).then((response) => {
        if (response.status === 200) {
          this.users_suscribed = response.body.data;
          this.setValuesTable();
        }
        this.load = false;
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
        }
      })
    },
    getForm(){
      this.$http.get(`marketing/forms/${this.$route.params.form}`, { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
        if (response.status === 200) {
          this.form = response.body.data;
          this.fieldsForm();
        }
        this.load = false;
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
        }
        this.load = false;
      })
    },
    setValuesTable() {
      this.rows = [];
      this.users_suscribed.data.forEach(user => {
        this.createTable(user);
      });
    },
    fieldsForm() {
      this.filters.fields = this.form.fields.map((field) => {
        return {
          text: field.description,
          value: {
            id: field.id,
            name: field.name,
            description: field.description
          }
        }
      });

      this.columnsFilter = this.filters.fields.filter((field) => {
        if(field.value.name != 'email') {
          return field;
        }
      });

      this.getUsersSuscribed();
    },
    tableFields() {
      this.fields = this.form.fields.map((field) => {
        return {
          key: field.name,
          label: field.description,
          sortable: true
        }
      });

      this.fields.push(
        // { key: 'email', label: 'Email', sortable: true },
        // { key: 'created_at', label: 'Created at', sortable: true},
        { key: 'actions', label: '' }
      );
    },
    createTable(row) {
      let arr = [];
      arr.push(['id', row.id]);

      row.input_values.forEach(input => {
        let name = this.filters.fields.find(field => field.value.id == input.field_id).value.name;
        arr.push([name, input.value]);
      });

      // arr.push(['created_at', row.created_at]);

      this.rows.push(Object.fromEntries(arr));
    },
    addFilter(value) {
      this.filter_fields.push({ id: value.id, name: value.name, description: value.description, value: String() });
      this.filters.fields = this.filters.fields.map((field) => {
        if(field.value.id == value.id) {
          return {
            text: value.description,
            value: {
              id: value.id,
              name: value.name,
              description: value.description
            },
            disabled: true
          }
        } else {
          return {
            ...field
          }
        }
      });
      this.filters.field = null;
    },
    removeFilter(index, input) {
      this.filter_fields.splice(index, 1);

      this.filters.fields = this.filters.fields.map((field) => {
        if(field.value.id == input.id) {
          return {
            text: input.description,
            value: {
              id: input.id,
              name: input.name,
              description: input.description
            }
          }
        } else {
          return {
            ...field
          }
        }
      });
    },
    setColumnTable(){
      this.fields = [];
      this.fields.push(
        { key: 'email', label: 'Email', sortable: true },
        // { key: 'created_at', label: 'Created at', sortable: true},
        { key: 'actions', label: '' }
      );
      this.selected.forEach(column => {
        if(!this.fields.find(field => field.key == column.name)) {
          this.fields.unshift({ key: column.name, label: column.description, sortable: true });
        }
      });
    },
    filter() {
      this.getUsersSuscribed();
      this.setValuesTable();
    }
  }
};
</script>
